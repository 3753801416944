<template>
  <Main>
    <template v-slot:content>
      <div class="order container">
        <div v-if="features.length && order" class="order__container">
          <div class="order__table">
            <div class="order__table-head">
              <div class="order__table-name">Name</div>
              <div class="order__table-item">Price</div>
              <div class="order__table-item">Quantity</div>
              <div class="order__table-item">Amount</div>
            </div>
            <div v-for="item in order.services_summary" :key="item.name">
              <div class="order__table-body">
                <div class="order__table-name">{{ item.title }}</div>
                <div class="order__table-item">{{ item.price_per_item_formatted }}</div>
                <div class="order__table-item">{{ item.count }}</div>
                <div class="order__table-item">{{ item.total_price_formatted }}</div>
              </div>
            </div>
          </div>
          <div v-if="!is_trial" class="order__list">
            <div
              v-for="item in features"
              :key="item.name"
              class="order__list-item"
            >
              <div
                class="order__check-container"
                @click="addFeatures(item.name)"
              >
                <div
                  v-if="choseFeatures.includes(item.name)"
                  class="order__list-item-check active"
                >
                  <CheckStyleIcon />
                </div>
                <div v-else class="order__list-item-check"></div>
              </div>
              <div
                class="order__list-item-desc"
                @click="addFeatures(item.name)"
              >
                <div class="order__list-item-title">{{ item.title }}</div>
                <div class="order__list-item-text">{{ item.description }}</div>
              </div>
              <div
                class="order__count-block"
                v-if="item.name === 'floor_plan_3d'"
              >
                <MinuseIcon @click="decreaseCount(item.name)" />
                <div>{{ count }}</div>
                <PluseIcon @click="increaseCount(item.name)" />
              </div>
            </div>
          </div>
          <div class="order__bottom-container">
            <div class="order__back desc">
              <div @click="handleBack" class="order__back-button">
                {{ $t("message.Back") }}
              </div>
            </div>
            <div class="order__total-container">
              <div class="order__total">
                <div class="order__total-text">{{ $t("message.Amount") }}</div>
                <div v-if="order" class="order__total-count">
                  {{ order.subtotal_formatted }}
                </div>
              </div>
              <div class="order__total">
                <div class="order__total-text">{{ $t("message.VAT") }}</div>
                <div v-if="order" class="order__total-count">
                  {{ order.tax_amount_formatted }}
                </div>
              </div>
              <div class="order__total">
                <div class="order__total-text">
                  {{ $t("message.In_total") }}
                </div>
                <div v-if="order" class="order__total-count">
                  {{ order.price_formatted }}
                </div>
              </div>
              <div class="order__back-button-container">
                <div class="order__back mob">
                  <div @click="handleBack" class="order__back-button">
                    {{ $t("message.Back") }}
                  </div>
                </div>
                <div
                  @click="handleFinishOrder"
                  v-if="!is_trial"
                  class="order__to-pay"
                >
                  {{ $t("message.To_Pay") }}
                </div>
                <div @click="handleFinishOrder" v-else class="order__to-pay">
                  {{ $t("message.Next_Step") }}
                </div>
                <!-- Індикатор завантаження під час обробки платежу -->
                <div
                  v-if="isPaymentProcessing"
                  class="order__payment-processing"
                >
                  <div class="order__payment-spinner"></div>
                  <div class="order__payment-text">
                    {{
                      $t("message.Processing_Payment") ||
                      "Processing payment..."
                    }}
                  </div>
                </div>

                <!-- Повідомлення про статус платежу -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer></template>
  </Main>
</template>

<script>
import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";

import CheckStyleIcon from "~/assets/images/icons/check-style.svg";
import PluseIcon from "~/assets/images/icons/pluse.svg";
import MinuseIcon from "~/assets/images/icons/minuse.svg";

export default {
  data() {
    return {
      order: null,
      features: [],
      choseFeatures: [],
      is_trial: false,
      count: 1,
      isPaymentProcessing: false,
      paymentStatus: null,
      paymentStatusType: "info", // 'info', 'success', 'error'
      loading: false,
      parameterCacheId: null
    };
  },
  mounted() {
    this.getOrder();

    // Отримуємо parameterCacheId з URL
    this.parameterCacheId = this.getParameterCacheId();

    // Додаємо слухача для повідомлень від батьківського вікна
    window.addEventListener("message", this.handleParentMessage);
  },
  beforeDestroy() {
    // Видаляємо слухача перед знищенням компонента
    window.removeEventListener("message", this.handleParentMessage);
  },
  components: {
    Main,
    CheckStyleIcon,
    PluseIcon,
    MinuseIcon,
  },
  methods: {
    getOrder() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getOrderItem(url)
          .then((res) => {
            this.order = res;
            if (this.order.items[0].project.service_type.value == "trial") {
              this.is_trial = true;
            }

            this.getOrderFeaturesList(this.order.id);

            this.choseFeatures = [];

            const floorPlanFeature = this.order.features.find(
              (item) => item.name === "floor_plan_3d",
            );

            if (floorPlanFeature && floorPlanFeature.count !== undefined) {
              this.count = floorPlanFeature.count;
            }

            if (this.order.features.length > 0) {
              for (let i = 0; this.order.features.length > i; i++) {
                this.choseFeatures.push(this.order.features[i].name);
              }
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    getOrderFeaturesList(id) {
      try {
        baseApi
          .getOrderFeaturesList(id)
          .then((res) => {
            this.features = res;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    handleBack() {
      if (this.order.items.length > 1) {
        let url = `?projects=`;
        for (let a = 0; this.order.items.length > a; a++) {
          if (this.order.items.length == a + 1) {
            url += `${this.order.items[a].project.id}`;
          } else {
            url += `${this.order.items[a].project.id},`;
          }
        }

        this.$router.push({
          path: ROUTE.PROJECT_ROOMS.replace(
            ":id",
            this.order.items[this.order.items.length - 1].project.id + url,
          ),
        });
      } else {
        this.$router.push({
          path: ROUTE.PROJECT_ROOMS.replace(
            ":id",
            this.order.items[0].project.id,
          ),
        });
      }
    },
    addFeatures(name, changeCount = null) {
      const url = `/${this.$route.params.id}`;
      const data = {
        features: [...this.choseFeatures],
        features_meta: {
          floor_plan_3d: {
            count: this.count,
          },
        },
      };

      if (data.features.includes(name)) {
        const index = data.features.indexOf(name);
        if (index > -1) {
          if (name !== "floor_plan_3d") {
            data.features.splice(index, 1);
          }
          if (name === "floor_plan_3d" && this.count <= 1) {
            data.features.splice(index, 1);
          }
        }
      } else {
        data.features.push(name);
      }

      if (name === "floor_plan_3d") {
        if (changeCount !== null) {
          this.count = Math.max(1, this.count + changeCount);
        }

        data.features_meta.floor_plan_3d = {
          count: this.count,
        };
      }

      try {
        projectApi
          .setOrderItems(url, data)
          .then((res) => {
            this.getOrder();
          })
          .catch((error) => {
            console.error("Ошибка при обновлении:", error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    handleFinishOrder() {
      this.initiateOnOfficePayment()
      this.$router.push({
        path: ROUTE.ORDERS_SUCCESS.replace(":id", this.$route.params.id),
      });
    },

    increaseCount(name) {
      this.count++;
      this.addFeatures(name);
    },

    decreaseCount(name) {
      if (this.count > 1) {
        this.count--;
        this.addFeatures(name);
      }
    },


    getParameterCacheId() {
      // Спочатку перевіряємо URL-параметр
      const urlParam = this.getUrlParameter("parameterCacheId");
      if (urlParam) return urlParam;

      // Якщо в URL нема, беремо зі сховища
      if (this.$onofficeParams && this.$onofficeParams.getParameterCacheId()) {
        return this.$onofficeParams.getParameterCacheId();
      }

      // Запасне значення, якщо нічого не знайдено
      return "";
    },

    // Функція для отримання значення параметра з URL
    getUrlParameter(sParam) {
      const url = window.location.search.substring(1);
      const urlVariables = url.split("&");

      for (let i = 0; i < urlVariables.length; i++) {
        const parameterName = urlVariables[i].split("=");

        if (parameterName[0] === sParam) {
          return parameterName[1] === undefined
            ? true
            : decodeURIComponent(parameterName[1]);
        }
      }
      // Якщо параметр не знайдено, повертаємо значення за замовчуванням
      return "";
    },

    // Ініціює платіж через OnOffice
    initiateOnOfficePayment() {
      if (!this.order || !this.parameterCacheId) {
        this.showPaymentStatus(
          "Error: Unable to initiate a payment without order data or Parametercachheid",
          "error",
        );
        return;
      }

      this.isPaymentProcessing = true;
      this.paymentStatus = null;

      // Використовуємо axios замість fetch для консистентності з іншими частинами додатку
      this.$axios
        .post(`/api/v1/orders/${this.order.id}/generate-payment-data`, {
          parameterCacheId: this.parameterCacheId,
        })
        .then((response) => {
          const data = response.data;
          console.log("Отримано дані платежу:", data);

          // Перевіряємо наявність даних платежу
          if (data.payment_data || data.payment_data_json) {
            const paymentData = JSON.stringify(data.payment_data);

            // Відправляємо дані через postMessage
            this.postDataToOnOffice(paymentData);

            this.showPaymentStatus("Payment data has been sent", "info");
          } else {
            throw new Error("Incorrect data format from server");
          }
        })
        .catch((error) => {
          console.error("Помилка при ініціації платежу:", error);
          this.showPaymentStatus(
            `Error when initiating payment: ${
              error.message || "Unknown error"
            }`,
            "error",
          );
        })
        .finally(() => {
          this.isPaymentProcessing = false;
        });
    },

    // Відправляє дані платежу батьківському вікну (перейменовано для відповідності оригінальному коду)
    postDataToOnOffice(jsonData) {
      console.log("Відправляємо дані платежу батьківському вікну:", jsonData);
      window.parent.postMessage(jsonData, "*");
    },

    // Обробник повідомлень від батьківського вікна
    handleParentMessage(event) {
      console.log("Отримано повідомлення від батьківського вікна:", event.data);

      try {
        // Спроба розпарсити отримане повідомлення
        const data =
          typeof event.data === "string" ? JSON.parse(event.data) : event.data;

        // Обробка різних типів повідомлень від OnOffice
        if (data.onOfficeType) {
          if (data.onOfficeType === "onOffice.service.refreshParent") {
            this.getOrder();
            this.showPaymentStatus(
              "Payment has been successfully processed",
              "success",
            );
          } else if (
            data.onOfficeType === "onOffice.service.refreshParentAndClose"
          ) {
            this.getOrder();
            this.showPaymentStatus(
              "Payment has been successfully processed",
              "success",
            );
          }
        }

        // Обробка повідомлень про статус платежу
        if (data.status) {
          if (data.status === "success") {
            this.showPaymentStatus(
              "Payment has been successfully processed",
              "success",
            );
          } else if (data.status === "error") {
            this.showPaymentStatus(
              `Error when payment: ${data.message || "Unknown error"}`,
              "error",
            );
          }
        }
      } catch (e) {
        console.log("Помилка обробки повідомлення:", e);
      }
    },

    // Показує повідомлення про статус платежу
    showPaymentStatus(message, type = "info") {
      this.paymentStatus = message;
      this.paymentStatusType = type;

      // Автоматичне очищення інформаційних повідомлень
      if (type === "info") {
        setTimeout(() => {
          if (this.paymentStatus === message) {
            this.paymentStatus = null;
          }
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;

  .desc {
    display: block;
  }

  .mob {
    display: none;
  }

  &__container {
    margin-top: 30px;
    min-width: 900px;
    max-width: 1000px;
    padding: 30px;
    border-radius: 15px;
    border: 1px #d7d9dd;
    background: #f9f9f9;
  }

  &__list-item {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin: 10px 0;
    position: relative;
  }

  &__list-item {
    &:nth-child(3) {
      border-bottom: 1px solid #d7d9dd;
    }
  }

  &__check-container {
    margin-right: 15px;
  }

  &__list-item-check {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #adadad;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      background: #000;

      svg {
        display: block;
      }
    }
  }

  &__list-item-title {
    font-family: "Mirador";
    font-size: 20px;
    padding-bottom: 5px;
  }

  &__list-item-text {
    color: #8e8e8e;
    font-size: 16px;
  }

  &__bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 30px;
  }

  &__back-button {
    width: 215px;
    padding: 15px;
    border-radius: 8px;
    background: #dadee7;
    color: #191919;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #b3b5b9;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  &__total-text,
  &__total-count {
    color: #191919;
    font-size: 20px;
    font-weight: 600;
  }

  &__total-text {
    font-size: 18px;
  }

  &__to-pay {
    width: 215px;
    padding: 15px;
    border-radius: 8px;
    background: #191919;
    color: #fff;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #000;
    }
  }
  &__table {
    border-bottom: 1px solid #d7d9dd;
    margin: 0 0 25px 0;
    padding: 0 0 25px 0;
  }
  &__table-head {
    display: flex;
    color: #8e8e8e;
    font-size: 16px;
    padding-bottom: 10px;
  }
  &__table-body {
    display: flex;
    color: #000;
    font-size: 20px;
    font-family: "Mirador";
  }
  &__table-name {
    width: calc(100% - 350px);
  }
  &__table-item {
    width: 150px;
    position: relative;
    left: 70px;
    padding-bottom: 20px;
  }
  &__count-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #d7d9dd;
    background: #f9f9f9;
    position: absolute;
    right: 0;

    svg {
      &:nth-child(1) {
        position: relative;
        right: 2px;
      }
      &:nth-child(2) {
        position: relative;
        left: 2px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__list-item-desc {
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1350px) {
    &__container {
      min-width: 750px;
      max-width: 750px;
    }
    &__list-item-title {
      font-size: 20px;
    }
  }

  @media (max-width: 991px) {
    &__container {
      min-width: 90%;
      max-width: 90%;
      padding: 20px;
    }
  }

  @media (max-width: 568px) {
    &__container {
      min-width: 100%;
      max-width: 100%;
    }
    &__list-item-title {
      font-size: 18px;
    }

    .desc {
      display: none;
    }

    .mob {
      display: block;
    }

    &__total-container {
      width: 100%;
    }

    &__back-button-container {
      display: flex;
      justify-content: space-between;
    }

    &__back-button,
    &__to-pay {
      width: fit-content;
      padding: 15px 40px;
    }
  }
}
</style>
